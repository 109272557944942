import React from 'react'
import { toast } from 'react-toastify'
import {
  Box, Columns, DashboardModule, LoadingModule, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'
import { useModals, useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import blankState from '@components/assets/blankState.svg'
import useReduxAction from '@hooks/useReduxAction'
import useClientYoutubeChannels from '@hooks/useClientYoutubeChannels'
import useYoutubeChannels from '@hooks/useYoutubeChannels'
import EditYoutubeChannelModal from '@modals/EditYoutubeChannelModal'

import custom from '@styles/custom.module.scss'
import Channel from './components/Channel'
import Tools from './components/Tools'

const defaultState = {
  selectedVideoServiceType: [1],
  showEditYoutubeChannelModal: false,
}

const deleteYoutubeChannel = (deleteFn, setState) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    setState({ showEditYoutubeChannelModal: false })
  })
}

const updateYoutubeChannel = (channelParams, updateFn, setState) => {
  updateFn(channelParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    setState({ showEditYoutubeChannelModal: false })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    EditYoutubeChannelModal: {
      closeModal: () => setState({ showEditYoutubeChannelModal: false }),
      deleteYoutubeChannel: deleteFn => deleteYoutubeChannel(deleteFn, setState),
      updateYoutubeChannel: (channelParams, updateFn) => updateYoutubeChannel(channelParams, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const YoutubeList = () => {
  const [state, setState] = useSetState(defaultState)
  const { selectedVideoServiceType, showEditYoutubeChannelModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const channelOptions = {
    ...selectedVideoServiceType?.length > 0 && { selectedVideoServiceType: selectedVideoServiceType.join(',') },
  }

  useReduxAction('youtubeChannels', 'loadYoutubeChannels', channelOptions, [selectedVideoServiceType])

  const { filteredYoutubeChannels, hasfilteredYoutubeChannels, loading } = useYoutubeChannels({
    selectedVideoServiceType,
  })

  useReduxAction('clientYoutubeChannels', 'loadClientYoutubeChannels', {}, [])

  const { loading: clientLinkingLoading } = useClientYoutubeChannels()

  const updateSelectedVideoServiceType = (checked, val) => {
    if (checked){
      setState({ selectedVideoServiceType: [...selectedVideoServiceType, val] })
    } else {
      setState({ selectedVideoServiceType: selectedVideoServiceType.filter(i => i !== val) })
    }
  }

  const pageContext = {
    callbacks: {
      showEditYoutubeChannelModal: (payload) => {
        setModalData('EditYoutubeChannelModal', payload)
        setState({ showEditYoutubeChannelModal: true })
      },
      toggleSelectedVideoServiceType: (checked, val) => updateSelectedVideoServiceType(checked, val),
    },
    selectedVideoServiceType,
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          activeTabBarItemKey="clients"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          title="Youtube Channel Management"
          offset={{ left: 70 }}
        />
        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns
            boxProps={{ marginTop: 'large' }}
            flexDirection={['column', 'column', 'row']}
          >
            <Columns.Main>
              <DashboardModule title="Channels">
                <Box flexDirection="column" className={custom.scroll}>
                  <Box flexDirection="column" maxHeight="75vh" overflowY="auto">
                    {!clientLinkingLoading && hasfilteredYoutubeChannels && filteredYoutubeChannels.map(channel => (
                      <Channel key={channel.channelId} channel={channel} />
                    ))}
                    <LoadingModule loading={loading || clientLinkingLoading} boxProps={{ border: 0 }} />
                  </Box>
                  {!loading && !hasfilteredYoutubeChannels && !clientLinkingLoading && (
                    <DashboardModule.BlankState imageUrl={blankState}>
                      <DashboardModule.BlankState.Title>No Channels Available</DashboardModule.BlankState.Title>
                      <DashboardModule.BlankState.Paragraph>
                        Try adjusting your filters.
                      </DashboardModule.BlankState.Paragraph>
                    </DashboardModule.BlankState>
                  )}
                </Box>
              </DashboardModule>
            </Columns.Main>
            <Columns.Sidebar>
              <DashboardModule title="Tools">
                <Tools />
              </DashboardModule>
            </Columns.Sidebar>
          </Columns>
        </Box>
        <EditYoutubeChannelModal
          callbacks={callbacks('EditYoutubeChannelModal', setState)}
          showModal={showEditYoutubeChannelModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default YoutubeList
