import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, Columns, DashboardModule, PageHeader, Text,
} from '@campaignhub/suit-theme'

import checkUserEmptyAddressField from '@functions/checkUserEmptyAddressField'
import useUserV3, { useUserForm } from '@hooks/useUserV3'
import UserDetail from '@components/UserDetail'

const defaultState = {
  createUser: false,
  customErrors: [],
}

const goBack = (navigate) => {
  navigate(-1)
}

const updateUser = (entityParams, updateFn, navigate) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('User has been updated!')
    goBack(navigate)
  })
}

const createUser = (entityParams, createFn, navigate) => {
  createFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('User has been created!')
    goBack(navigate)
  })
}

const confirmDelete = (user, deleteFn, navigate) => {
  swal
    .fire({
      title: 'Delete User?',
      text: 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn(user).then(({ success, errors }) => {
          if (!success && errors){
            toast.warn(errors[0])
            return
          }
          toast('User has been deleted!')
          goBack(navigate)
        })
      }
    })
}

const confirmRedirect = (navigate) => {
  swal
    .fire({
      title: 'Leave User Management Details?',
      text: 'You have unsaved changes. Are you sure you want to go back? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        goBack(navigate)
      }
    })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    UserDetail: {
      setCustomErrors: val => setState({ customErrors: val }),
    },
  }

  return componentCallbacks[component] || {}
}

const UserEdit = (props) => {
  const { isNew } = props

  const { userId } = useParams()
  const [state, setState] = useSetState(defaultState)
  const { customErrors } = state

  const navigate = useNavigate()

  const { users } = useSelector(reduxState => reduxState.entities)
  const user = users[userId] || {}
  const userForm = useUserForm(user)
  const {
    entityState,
    entityState: {
      id,
    },
    saveEnabled,
    errors,
    initialValue,
  } = userForm

  const userPayload = useUserV3(user)
  const {
    callbacks: {
      updateUser: updateFn,
      createUser: createFn,
      deleteUser: deleteFn,
    },
    updating,
  } = userPayload

  const save = () => {
    if (Object.keys(errors).length > 0 || Object.keys(customErrors).length > 0){
      toast.warn('One or more fields did not match the validation.')
      return
    }
    if (isNew){
      createUser(checkUserEmptyAddressField(entityState), createFn)
    } else {
      updateUser(entityState, updateFn)
    }
  }
  const checkRedirect = () => {
    let existingChanges = false
    const fields = Object.keys(entityState)
    fields.forEach((key) => {
      if (entityState[key] !== initialValue[key] && typeof entityState[key] === 'string'){
        existingChanges = true
      } else if (typeof entityState[key] === 'object' && !JSON.stringify(entityState[key]) === JSON.stringify(initialValue[key])){
        existingChanges = true
      }
    })
    if (existingChanges){
      confirmRedirect(navigate)
    } else {
      goBack(navigate)
    }
  }

  return (
    <>
      <PageHeader
        activeTabBarItemKey="users"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        title="User Management"
        offset={{ left: 70 }}
      />
      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {!isNew && id === null && (
              <Text marginTop="large" marginBottom="large">
                User does not exists.
              </Text>
            )}
            {!isNew && id !== null && (
              <UserDetail
                userForm={userForm}
                customErrors={customErrors}
                callback={callbacks('UserDetail', setState)}
              />
            )}
            {isNew && (
              <UserDetail
                userForm={userForm}
                customErrors={customErrors}
                callback={callbacks('UserDetail', setState)}
              />
            )}
          </Columns.Main>
          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Box padding="small" marginTop="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    size="medium"
                    onClick={() => checkRedirect()}
                  >
                    Back
                  </Button>
                </Box>
                <Box padding="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={() => confirmDelete(entityState, deleteFn, navigate)}
                    size="medium"
                    disabled={isNew || (!isNew && id === null)}
                  >
                    Delete User
                  </Button>
                </Box>
                <Box padding="small" marginBottom="small">
                  <Button
                    buttonStyle="primaryCreate"
                    loading={updating}
                    onClick={() => save()}
                    size="medium"
                    disabled={!isNew && id === null && !saveEnabled}
                  >
                    {isNew ? 'Create' : 'Save'}
                  </Button>
                </Box>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

UserEdit.propTypes = {
  isNew: PropTypes.bool,
}

export default UserEdit
