import React from 'react'
import { Outlet } from 'react-router-dom'

import { MainContent } from '@campaignhub/suit-theme'
import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@suiteThemeCustom/TopBar/components/LoggedInUser'
import TimeTracker from '@suiteThemeCustom/TopBar/components/TimeTracker'
import Notifications from '@suiteThemeCustom/TopBar/components/Notifications'
import TopBar from '@suiteThemeCustom/TopBar'

import ServiceJobsSearch from '@components/ServiceJobsSearch'
import MainNavigation from '@sections/Studio/components/MainNavigation'

const Reports = () => {
  const { callbacks: { toggleNavigation }, showReports } = useMainNavigation()

  if (!showReports) return null

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        notificationComponent={<Notifications />}
        searchComponent={<ServiceJobsSearch />}
        timeTrackerComponent={<TimeTracker />}
      />

      <MainNavigation minimized />

      <MainContent offset={{ top: TopBar.topBarHeight, left: 70 }}>
        <Outlet />
      </MainContent>
    </>
  )
}

export default Reports
