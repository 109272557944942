import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'

import {
  Box, Button, FormField, LoadingBubbles, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { getUppyResourceOptions, getComponentCallbacks } from '@functions/uploadModal'

import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useUppyUploadResource from '@hooks/useUppyUploadResource'

import CustomUploader from './components/CustomUploader'
import ResourcePrompts from './components/ResourcePrompts'
import FailedItemList from './components/FailedItemList'
import RecentlyUploadedList from './components/RecentlyUploadedList'

const defaultState = {
  addedCount: [],
  allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/DNG', 'image/CR2', 'image/ARW'],
  allowUpload: true,
  currentlyAddedResource: null,
  doneUpload: false,
  lastDeletedId: 0,
  lastFailedBatch: [],
  lastUploadedBatch: [],
  recentlyFailed: [],
  recentlyUploaded: [],
  resourceOptions: [],
  showUploadNewBatch: false,
  uploadLoading: false,
}

const insertRecentlyUploadedBatch = (newUploadList, uploadList, newFailedList, failedList, setState) => {
  setState({ lastUploadedBatch: [], lastFailedBatch: [], doneUpload: false })
  setState({
    recentlyUploaded: uploadList.concat(newUploadList),
    recentlyFailed: failedList.concat(newFailedList),
  })
}

const removeRecentlyDeleted = (id, list, setState) => {
  setState({ lastDeletedId: 0 })
  setState({ recentlyUploaded: list.filter(x => x.id !== id) })
}

const UploadResourceModal = (props) => {
  const {
    callbacks,
    modalKey,
    serviceJob,
    showModal,
    title,
    titleSecondLine,
    serviceJobOptions,
    type,
    fileTypesAllowed,
    resourceTypes,
  } = props

  const { checkProgress, checkUploadStatus, deleteFn, scanFolderFn } = callbacks

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources, fileServiceContainers } = entities

  const { currentUser } = useCurrentUser()

  const [state, setState] = useSetState(defaultState)
  const {
    addedCount,
    allowedFileTypes,
    allowUpload,
    currentlyAddedResource,
    doneUpload,
    lastDeletedId,
    lastFailedBatch,
    lastUploadedBatch,
    recentlyFailed,
    recentlyUploaded,
    resourceOptions,
    showUploadNewBatch,
    uploadLoading,
  } = state

  const containerName = `service-jobs-${DateTime.fromISO(serviceJob.createdAt, { zone: 'utc' }).toFormat('yyyy')}`

  const { loading } = useReduxAction('fileServiceContainers', 'loadFileServiceContainer', containerName, [])
  const { loading: resourcesloading } = useSelector(reduxState => reduxState.resources)

  const { loading: serviceJobLoading } = useReduxAction('serviceJobs', 'loadServiceJobV1', {
      includes: ['service', 'campaign', 'client', 'details', 'histories', 'postProductionChecklistItems', 'users', 'notes', 'tags'].join(','),
    }, [serviceJob.id, doneUpload], {
      dispatchAction: (action, requestOptions) => action(serviceJob.id, requestOptions),
      shouldPerformFn: () => doneUpload && type === 'Resources',
    })

  useEffect(() => {
    if (lastDeletedId > 0){
      removeRecentlyDeleted(lastDeletedId, recentlyUploaded, setState)
    }
  }, [resources])

  useEffect(() => {
    if (fileTypesAllowed){
      setState({ allowedFileTypes: fileTypesAllowed.split(',') })
    }
  }, [fileTypesAllowed])

  useEffect(() => {
    if (doneUpload){
      setState({ showUploadNewBatch: true, allowUpload: false })
      if (lastUploadedBatch.length > 0 || lastFailedBatch.length > 0){
        insertRecentlyUploadedBatch(lastUploadedBatch, recentlyUploaded, lastFailedBatch, recentlyFailed, setState)
      }
    }
  }, [doneUpload])

  const fileServiceContainer = Object.values(fileServiceContainers).find(x => x.container === containerName)
  const uppyCallbacks = { ...getComponentCallbacks('uppy', setState) }
  const uppyInstances = useUppyUploadResource(resourceOptions)?.filter(x => x !== null && x !== undefined)

  const uploadedResources = []
  const uploadPercentagesRef = useRef({})
  const averagePercentageRef = useRef()

  const onCompleteUploads = (failedItems) => {
    scanFolderFn(serviceJob.id).then(({ success, errors, data }) => {
      if (!success && errors){
        toast.warn(errors[0])
      }

      uploadPercentagesRef.current = {}
      averagePercentageRef.current = 0

      setState({ uploadLoading: false, doneUpload: true, lastUploadedBatch: data, ...(failedItems.length) && { lastFailedBatch: [...new Set(failedItems)] } })
    })
  }

  uppyInstances.forEach((uppyInstance) => {
    const handleUploadProgress = (data) => {
      const uploader = uppyInstance?.opts?.id?.split('-')
      const uploaderId = uploader.length ? uploader[1] : null
      if (data && uploaderId && uploadPercentagesRef.current[uploaderId]){
        uploadPercentagesRef.current[uploaderId] = data
      }

      if (uploadPercentagesRef.current && averagePercentageRef.current){
        const percentages = Object.values(uploadPercentagesRef.current)
        const total = percentages?.reduce((sum, percentage) => sum + percentage, 0)
        checkProgress(percentages.length && total > 0 ? (total / percentages.length).toFixed(0) : 0)
        averagePercentageRef.current.textContent = percentages.length && total > 0 ? `${(total / percentages.length).toFixed(0)}%` : '0%'
      }
    }

    const handleUploadComplete = (result) => {
      if (result){
        const uploadedFile = {}

        if (result.successful.length && !uploadedResources.find(x => x.resourceType === result.successful[0].meta.resourceTypeId)){
          uploadedFile.resourceType = result.successful[0].meta.resourceTypeId
          uploadedFile.successful = result.successful
        }
        if (result.failed.length) uploadedFile.failed = result.failed
        if (uploadedFile?.resourceType) uploadedResources.push(uploadedFile)
      }
      if (uploadedResources.length === addedCount.length && uploadLoading){
        onCompleteUploads(uploadedResources.flatMap(item => item.failed).filter(x => x !== null && x !== undefined))
      }
    }

    uppyInstance.on('progress', handleUploadProgress)
    uppyInstance.on('complete', handleUploadComplete)
  })

  useEffect(() => {
    checkUploadStatus(recentlyUploaded)
  }, [recentlyUploaded])

  const resetUploader = () => {
    setState({
      addedCount: [],
      allowUpload: true,
      currentlyAddedResource: null,
      doneUpload: false,
      lastDeletedId: 0,
      lastFailedBatch: [],
      lastUploadedBatch: [],
      recentlyFailed: [],
      recentlyUploaded: [],
      resourceOptions: [],
      showUploadNewBatch: false,
      uploadLoading: false,
    })
  }

  const handleUpload = () => {
    if (addedCount){
      addedCount.forEach((x) => {
        if (!uploadPercentagesRef.current[x.resource.toString()]){
          uploadPercentagesRef.current[x.resource.toString()] = React.createRef()
        }
      })
    }
    setState({ uploadLoading: true })
  }

  useEffect(() => {
    if (resourceTypes.length && allowUpload){
      const resourceUppyOptions = resourceTypes
        .map(x => (getUppyResourceOptions(
          allowedFileTypes,
          serviceJobOptions,
          uppyCallbacks,
          x.id,
          fileServiceContainer,
          currentUser.id,
        )))
      setState({ resourceOptions: resourceUppyOptions })
    }
  }, [resourceTypes, allowUpload])

  const totalAddedFiles = addedCount?.reduce((accumulator, current) => accumulator + current.count, 0)

  return (
    <SidebarModal
      callbacks={callbacks}
      clickSafeZone
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title={title} titleSecondLine={titleSecondLine} />
      <SidebarModal.Content hasFooter={!!(showUploadNewBatch || totalAddedFiles)}>
        {((loading || resourcesloading) && !uploadLoading) && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />}
        <Box flexDirection="column" flexShrink={0}>
          <ResourcePrompts
            resourceTypes={resourceTypes}
            serviceJob={serviceJob}
            uploadState={state}
          />
        </Box>
        {!loading && allowUpload && (
          <Box flexDirection="column" marginBottom="large">
            {uppyInstances.length && uppyInstances?.length === resourceTypes.length && (
              <FormField direction="column" marginTop="medium">
                {resourceTypes.map(resource => (
                  <CustomUploader
                    key={resource.id}
                    currentlyAddedResource={currentlyAddedResource}
                    resource={resource}
                    uploadLoading={uploadLoading}
                    callbacks={{
                    handleAddFiles: fileCount => setState({ addedCount: [...addedCount, fileCount], currentlyAddedResource: null }),
                }}
                    uppy={uppyInstances.find(x => x?.opts?.id?.includes(resource.id.toString()))}
                  />
                ))}
              </FormField>
            )}
            {uppyInstances?.length !== resourceTypes.length && (
              <FormField direction="column" marginTop="large">
                <Text marginBottom="small" fontSize="xsmall">
                  Could not load uppy service
                </Text>
              </FormField>
            )}
          </Box>
        )}

        {recentlyFailed.length > 0 && (
          <SidebarModal.ExpandableSectionBox label={`Failed Items (${recentlyFailed.length})`}>
            <FailedItemList list={recentlyFailed} />
          </SidebarModal.ExpandableSectionBox>
        )}

        {recentlyUploaded.length > 0 && (
          <SidebarModal.ExpandableSectionBox label={`Uploaded Items (${recentlyUploaded.length})`}>
            <RecentlyUploadedList
              list={recentlyUploaded}
              callbacks={{ ...getComponentCallbacks('recentlyuploadedlist', setState), deleteFn }}
              fileType={type}
            />
          </SidebarModal.ExpandableSectionBox>
        )}
      </SidebarModal.Content>

      {showUploadNewBatch && (
        <SidebarModal.Footer>
          <Button
            buttonStyle="primaryCreate"
            onClick={() => resetUploader()}
            size="medium"
            loading={serviceJobLoading || loading || resourcesloading}
          >
            Upload New Batch
          </Button>
        </SidebarModal.Footer>
      )}

      {totalAddedFiles && !doneUpload && !(recentlyUploaded.length || recentlyFailed.length) && (
        <SidebarModal.Footer>
          <Button
            buttonStyle="primaryCreate"
            onClick={() => handleUpload()}
            size="medium"
            disabled={uploadLoading || loading}
          >
            {uploadLoading || loading ? (
              <Text ref={averagePercentageRef} color="lightGrey"> 0% </Text>

            ) : (
              <Text color="white"> Upload {totalAddedFiles} File/s</Text>
            )}
          </Button>
        </SidebarModal.Footer>
      )}
    </SidebarModal>
  )
}

UploadResourceModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  serviceJobOptions: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  serviceJob: PropTypes.object,
  title: PropTypes.string,
  titleSecondLine: PropTypes.string,
  resourceTypes: PropTypes.array,
  fileTypesAllowed: PropTypes.string,
}

UploadResourceModal.defaultProps = {
  modalKey: 'UploadResourceModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <UploadResourceModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
