import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentLines } from '@fortawesome/pro-light-svg-icons'

import Comment from '@suiteThemeCustom/Comment'
import PageContext from '@contexts/pageContext'

const AssetComment = (props) => {
  const { comment } = props
  const { assetVideo, currentUser, fileComments, sasToken, selectedAssetVersionCount } = useContext(PageContext)
  const commenter = JSON.parse(comment.details.find(x => x.entityFieldType.name === 'Commenter')?.value).value
  const editOverlays = comment.details.filter(x => x.entityFieldType.name === 'EditOverlay')?.map(y => JSON.parse(y.value)?.value)
  const clientUploads = comment.details.filter(x => x.entityFieldType.name === 'ClientUpload')?.map(y => JSON.parse(y.value)?.value)
  const timestamp = comment.details.find(x => x.entityFieldType.name === 'Timestamp')
    ? JSON.parse(comment.details.find(x => x.entityFieldType.name === 'Timestamp')?.value).value : -1
  const fileComment = fileComments.find(x => x.id === comment.id)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingY: 'large',
        borderBottom: '1px dashed',
      }}
      flexDirection="row"
      disableHover
      key={comment.id}
    >
      <Box paddingBottom="xsmall" marginBottom="xsmall">
        <Box flexDirection="column" width="auto">
          {comment.commentIndex > -1 ? (
            <Text
              backgroundColor="green"
              borderRadius={20}
              color="white"
              fontSize="small"
              fontWeight={500}
              style={{ cursor: 'pointer' }}
              textAlign="center"
              lineHeight="34px"
              marginRight="large"
              width="34px"
            >
              {comment.commentIndex}
            </Text>
          ) : (
            <Box
              color="green"
              marginRight="large"
              marginLeft="small"
              width="34px"
              fontSize={27}
              textAlign="center"
            >
              <FontAwesomeIcon icon={faCommentLines} />
            </Box>
          )}
        </Box>
        <Comment
          assetVideo={assetVideo || null}
          boxProps={{ marginBottom: 0, borderBottom: 0, paddingBottom: 0 }}
          commentBody={comment.comment}
          commentImages={fileComment?.uri && sasToken && fileComment?.type?.startsWith('image/') ? [`${fileComment.uri}?${sasToken}`] : editOverlays}
          commentLinks={fileComment?.uri && sasToken && fileComment?.type && !fileComment.type.startsWith('image/') ? [`${fileComment.uri}?${sasToken}`] : clientUploads}
          commentTime={DateTime.fromISO(comment.createdAt, { zone: currentUser.timeZone }).toFormat('dd LLLL y, hh:mm a')}
          fullName={`${commenter} ${selectedAssetVersionCount > 1 ? `(Version ${comment.version })` : ''}`}
          hideImage
          timestamp={timestamp >= 0 ? timestamp : null}
        />
      </Box>
    </ListItem>
  )
}

AssetComment.propTypes = {
  comment: PropTypes.object,
}

export default AssetComment
