import { useContext } from 'react'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

const launchMultipleResourceUploadModal = (dispatch, showMultipleResourceUploadModal) => new Promise((resolve, reject) => {
  if (showMultipleResourceUploadModal){
    const payload = {
      callbacks: {
      },
    }

    showMultipleResourceUploadModal(payload)
    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showuseUploadMultipleResource not defined in PageContext callbacks'))
})

const useUploadMultipleResource = () => {
  const { callbacks } = useContext(PageContext)
  const { showMultipleResourceUploadModal } = callbacks || {}
  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      launchMultipleResourceUploadModal: () => launchMultipleResourceUploadModal(dispatch, showMultipleResourceUploadModal),
    },
  }
}

export default useUploadMultipleResource
