import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'

import ServiceJobList from '@components/ServiceJobList'

import useCurrentDate from '@hooks/useCurrentDate'

const defaultState = {
  showJobList: false,
}

const JobGroup = (props) => {
  const { serviceJobs, serviceJobGroupDate } = props

  const [state, setState] = useSetState(defaultState)
  const { showJobList } = state

  const { now } = useCurrentDate()

  useEffect(() => {
    if (serviceJobGroupDate >= DateTime.fromISO(now).toFormat('LLL dd, yyyy') || !serviceJobs?.length){
      setState({ showJobList: !showJobList })
    } else {
      setState({ showJobList: false })
    }
  }, [serviceJobGroupDate])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
      }}
      disableHover
      flexDirection="column"
      forceBorderBottom
    >
      <Box
        alignItems="center"
        borderBottom={showJobList ? '1px solid' : '0'}
        borderColor="lineColor"
        flexDirection="row"
        justifyContent="space-between"
        onClick={() => setState({ showJobList: !showJobList })}
        padding="large"
        style={{ cursor: 'pointer' }}
      >
        <Text fontSize="small" color="bodyFontLightColor">
          Showing {serviceJobs?.length}{serviceJobs?.length > 1 ? ' Jobs' : ' Job'}
          {serviceJobs?.length ? ` for ${serviceJobGroupDate}` : ''}
        </Text>
        <Box color="bodyFontLightColor" width="auto">
          <FontAwesomeIcon icon={showJobList ? faChevronUp : faChevronDown} size="sm" />
        </Box>
      </Box>
      {showJobList && (
        <ServiceJobList serviceJobs={serviceJobs?.length ? Object.values(serviceJobs) : []} providerView />
      )}
    </ListItem>
  )
}

JobGroup.propTypes = {
  serviceJobs: PropTypes.array,
  serviceJobGroupDate: PropTypes.string,
}

export default JobGroup
