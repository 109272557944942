import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, FileUploadZone, ListItem, SidebarModal, Text } from '@campaignhub/suit-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'

const CustomUploader = (props) => {
  const { callbacks, currentlyAddedResource, resource, uploadLoading, uppy } = props

  const { handleAddFiles } = callbacks

  const [open, setOpen] = useState(false)

  const addedFiles = uppy ? uppy.getFiles() : []

  useEffect(() => {
    if (uppy && uploadLoading && addedFiles.length){
      uppy.upload()
    }
  }, [uploadLoading])

  useEffect(() => {
    if (addedFiles.length && resource.id === currentlyAddedResource){
      handleAddFiles({ resource: resource.id, count: addedFiles.length })
    }
  }, [currentlyAddedResource])

  if (addedFiles.length){
    return (
      <SidebarModal.ExpandableSectionBox label={`Added ${resource.description} (${addedFiles.length})`} boxProps={{ marginBottom: 'medium' }}>
        <Box flexDirection="column" variant="white">
          {addedFiles.map(file => (
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'medium',
              }}
              disableHover
              key={file.id}
            >
              <Text variant="ellipsis" marginRight="small" marginBottom="small" fontSize="small">
                {file.name}
              </Text>
            </ListItem>
          ))}
        </Box>
      </SidebarModal.ExpandableSectionBox>
    )
  }

  return (
    <>
      <Box marginBottom="medium" flexDirection="column">
        <Button
          buttonStyle="secondary"
          onClick={() => setOpen(!open)}
          size="medium"
          style={{
          ...(open) && {
            borderBottom: 'none',
            borderRadius: '5px 5px 0 0',
          },
        }}
        >
          <Box flexDirection="row" justifyContent="space-between" alignItems="center">
            <Text>{resource.description}</Text>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          </Box>
        </Button>
        {open && (
          <Box border="1px solid" borderColor="lineColor" padding="medium" borderRadius="0 0 5px 5px" style={{ pointerEvents: uploadLoading ? 'none' : 'auto' }}>
            <FileUploadZone
              uppy={uppy}
              subTitle="Max Size is 2 GB"
            />
          </Box>
        )}
      </Box>
    </>
  )
}

CustomUploader.propTypes = {
  callbacks: PropTypes.object,
  currentlyAddedResource: PropTypes.number,
  resource: PropTypes.object,
  uploadLoading: PropTypes.bool,
  uppy: PropTypes.object,
}

export default CustomUploader
