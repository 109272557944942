import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'
import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as setAvailableActions from '@redux/modules/serviceJobSendToEsoft'

const postServiceJobSendToEsoft = (id, options, dispatch) => {
  const { postServiceJobSendToEsoft: createFn } = setAvailableActions

  return dispatch(createFn(id, options))
}

const useServiceJobSendToEsoft = () => {
    
  const entityReducer = useSelector(reduxState => reduxState.serviceJobSendToEsoft)
  const {
    errors,
    loaded,
    loading,
    result: serviceJobSendToEsoft,
  } = entityReducer

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      postServiceJobSendToEsoft: (serviceJobId, options) => postServiceJobSendToEsoft(serviceJobId, options, dispatch),
    },
    serviceJobSendToEsoft,
    errors,
    loaded,
    loading,
  }
}

export default useServiceJobSendToEsoft
