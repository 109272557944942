import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import { getUserInfo } from '@functions/staticWebAppUserInfo'
import api, { baseURL } from '@functions/api'

import Axios from 'axios'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/asset/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/asset/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/asset/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/asset/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/asset/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/asset/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/asset/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/asset/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/asset/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/asset/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/asset/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/asset/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadAssets(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().assets.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('v1/assets', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadAsset(id, options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/v1/assets/${id}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function createAsset(asset, options){
  const axios = Axios.create({
    baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  const swaKey = Buffer.from(JSON.stringify(getUserInfo())).toString('base64')

  return (dispatch) => {
    dispatch(createRequest())

    const promise = axios({
      method: 'POST',
      headers: {
        'x-ms-client-principal': swaKey,
      },
      url: '/v1/assets',
      data: asset,
      params: options,
    }).then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function createAssetDirect(asset, options){
  const config = {
    method: 'POST',
    data: asset,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/assets/direct', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateAssetDirect(asset, options){
  const config = {
    method: 'PUT',
    data: asset,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/v1/assets/${asset.id}/direct`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateAsset(asset, options){
  const axios = Axios.create({
    baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  const swaKey = Buffer.from(JSON.stringify(getUserInfo())).toString('base64')

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = axios({
      method: 'PUT',
      headers: {
        'x-ms-client-principal': swaKey,
      },
      url: `/v1/assets/${asset.id}`,
      data: asset.data,
      params: options,
    }).then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function deleteAsset(data){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/v1/assets/${data.id}`, {}, config)
      .then(() => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: data }
      })
      .catch((error) => {
        const errors = handleError(error)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
    }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
