import Axios from 'axios'
import { getUserInfo } from '@functions/staticWebAppUserInfo'

const urls = {
  development: 'http://localhost:7071/api',
  production: 'https://func-ch-api.azurewebsites.net/api',
  staging: 'https://func-ch-staging-api-2.azurewebsites.net/api',
}

const redirectURIs = {
  development: 'http://localhost:4280/systemManager/youtube/authorize',
  production: 'https://cms.contenthouse.io/systemManager/youtube/authorize',
  staging: 'https://staging-cms.contenthouse.io/systemManager/youtube/authorize',
}

const esoftEditorIds = {
  development: 242,
  production: 2923,
  staging: 242,
}

export const baseURL = urls[process.env.REACT_APP_APPLICATION_ENV]
export const esoftEditorId = esoftEditorIds[process.env.REACT_APP_APPLICATION_ENV]
export const redirectURI = redirectURIs[process.env.REACT_APP_APPLICATION_ENV]
export const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME

const axios = Axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

axios.interceptors.request.use(async (config) => {
  const swaKey = Buffer.from(JSON.stringify(getUserInfo())).toString('base64')

  const updatedConfig = {
    ...config,
    headers: {
      ...config.headers,
      'x-ms-client-principal': swaKey,
    },
  }

  return updatedConfig
},
  (error) => {
    // Do something with request error
    Promise.reject(error)
  })

const baseConfig = {
  method: 'get',
}

const api = (url, options = {}, config = {}) => {
  const mergedConfig = { ...baseConfig, ...config }

  return axios({
    ...mergedConfig,
    params: options,
    url,
  })
}

export default api
