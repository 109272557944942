import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, Columns, DashboardModule, PageHeader, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'

import ZoneDetails from '@components/ZoneDetails'
import useZoneV3, { useZoneForm } from '@hooks/useZoneV3'
import removeKeyWithEmptyValue from '@functions/removeKeyWithEmptyValue'

const goBack = (navigate) => {
  navigate(-1)
}

const confirmRedirect = (navigate) => {
  swal.fire({
    title: 'Leave Zone Management Details?',
    text: 'You have unsaved changes. Are you sure you want to go back? ',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it.',
    confirmButtonColor: '#DD6B55',
  })
  .then(({ value }) => {
    if (value){
      goBack(navigate)
    }
  })
}

const updateZoneClients = (entityParams, updateFn) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const updateZoneProviders = (entityParams, updateFn) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const createZone = (entityParams, createFn, navigate,
  linkedClients, linkedProviders, updateZoneClientsFn, updateZoneProvidersFn) => {
  createFn(entityParams).then(({ success, errors, result }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    if (result){
      const clients = []
      const providers = []

      linkedClients.map(client => clients.push(client.id))
      linkedProviders.map(provider => providers.push(provider.id))

      const zoneDetails = {
        id: result.id,
        linkedClients: clients,
        linkedProviders: providers,
      }

      updateZoneClients(zoneDetails, updateZoneClientsFn)
      updateZoneProviders(zoneDetails, updateZoneProvidersFn)
    }

    toast('Zone has been created!')
    goBack(navigate)
  })
}

const updateZone = (entityParams, updateFn, navigate,
  linkedClients, linkedProviders, updateZoneClientsFn, updateZoneProvidersFn) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    const clients = []
    const providers = []

    linkedClients.map(client => clients.push(client.id))
    linkedProviders.map(provider => providers.push(provider.id))

    const zoneDetails = {
      id: entityParams.id,
      linkedClients: clients,
      linkedProviders: providers,
    }

    updateZoneClients(zoneDetails, updateZoneClientsFn)
    updateZoneProviders(zoneDetails, updateZoneProvidersFn)

    toast('Zone has been updated!')
    goBack(navigate)
  })
}

const confirmDelete = (user, deleteFn, navigate) => {
  swal.fire({
    title: 'Delete Zone?',
    text: 'Are you sure? ',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it.',
    confirmButtonColor: '#DD6B55',
  })
  .then(({ value }) => {
    if (value){
      deleteFn(user).then(({ success, errors }) => {
        if (!success && errors){
          toast.warn(errors[0])
          return
        }
        toast('Zone has been deleted!')
        goBack(navigate)
      })
    }
  })
}

const defaultState = {
  customErrors: [],
  linkedClients: [],
  linkedProviders: [],
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    ZoneDetail: {
      setCustomErrors: val => setState({ customErrors: val }),
      toggleUpdateLinkedClients: clients => setState({ linkedClients: clients }),
      toggleUpdateLinkedProviders: providers => setState({ linkedProviders: providers }),
    },
  }

  return componentCallbacks[component] || {}
}

const ZoneEdit = (props) => {
  const { isNew } = props

  const [state, setState] = useSetState(defaultState)
  const { customErrors, linkedClients, linkedProviders } = state

  const navigate = useNavigate()
  const { zoneId } = useParams()

  const { zonesV3 } = useSelector(reduxState => reduxState.entities)
  const zone = zonesV3[zoneId] || {}

  const zoneForm = useZoneForm(zone)
  const {
    entityState,
    entityState: {
      id,
    },
    saveEnabled,
    errors,
    initialValue,
  } = zoneForm

  const zonePayload = useZoneV3(zone)
  const {
    callbacks: {
      createZone: createFn,
      deleteZone: deleteFn,
      updateZone: updateFn,
      updateZoneClients: updateZoneClientsFn,
      updateZoneProviders: updateZoneProvidersFn,
    },
    creating,
    updating,
  } = zonePayload

  const save = () => {
    if (Object.keys(errors).length > 0 || Object.keys(customErrors).length > 0){
      toast.warn('One or more fields did not match the validation.')
      return
    }

    if (isNew){
      createZone(removeKeyWithEmptyValue(entityState), createFn, navigate, linkedClients, linkedProviders,
        updateZoneClientsFn, updateZoneProvidersFn)
    } else {
      updateZone(removeKeyWithEmptyValue(entityState), updateFn, navigate, linkedClients, linkedProviders,
        updateZoneClientsFn, updateZoneProvidersFn)
    }
  }

  const checkRedirect = () => {
    let existingChanges = false
    const fields = Object.keys(entityState)
    fields.forEach((key) => {
      if (entityState[key] !== initialValue[key] && typeof entityState[key] === 'string'){
        existingChanges = true
      } else if (typeof entityState[key] === 'object' && !JSON.stringify(entityState[key]) === JSON.stringify(initialValue[key])){
        existingChanges = true
      }
    })
    if (existingChanges){
      confirmRedirect(navigate)
    } else {
      goBack(navigate)
    }
  }

  return (
    <>
      <PageHeader
        activeTabBarItemKey="zones"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        title="Zone Management"
        offset={{ left: 70 }}
      />
      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {!isNew && (
              <Text marginTop="large" marginBottom="large">
                Zone does not exists.
              </Text>
            )}
            {isNew && (
              <ZoneDetails
                zoneForm={zoneForm}
                customErrors={customErrors}
                callbacks={callbacks('ZoneDetail', setState)}
                linkedClients={linkedClients}
                linkedProviders={linkedProviders}
              />
            )}
          </Columns.Main>
          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Box padding="small" marginTop="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    size="medium"
                    onClick={() => checkRedirect()}
                  >
                    Back
                  </Button>
                </Box>
                <Box padding="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    size="medium"
                    onClick={() => confirmDelete(entityState, deleteFn, navigate)}
                    disabled={isNew || (!isNew && id === null)}
                  >
                    Delete Zone
                  </Button>
                </Box>
                <Box padding="small" marginBottom="small">
                  <Button
                    buttonStyle="primaryCreate"
                    size="medium"
                    disabled={!isNew && id === null && !saveEnabled}
                    loading={isNew ? creating : updating}
                    onClick={() => save()}
                  >
                    {isNew ? 'Create' : 'Save'}
                  </Button>
                </Box>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

ZoneEdit.propTypes = {
  isNew: PropTypes.bool,
}

export default ZoneEdit
