/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, Checkbox, Columns, DashboardModule, FormField, ListItem, PageHeader, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'

import YoutubeAuthentication from '@components/YoutubeChannelDetails/components/YoutubeAuthentication'
import { requestTokens, requestChannel, updateTokens } from '@functions/accessYoutube'
import videoServiceTypes from '@functions/videoServiceTypes'
import useYoutubeChannel from '@hooks/useYoutubeChannel'

const defaultState = {
  accessToken: '',
  authCode: null,
  channelId: '',
  channelName: '',
  channels: [],
  emailAddress: '',
  noRefreshToken: false,
  refreshToken: '',
  videoServiceTypeId: null,
}

const fragmentString = window.location.search.substring(1)

const confirmRedirect = (props) => {
  const { toggleRefreshState } = props
  swal.fire({
    title: 'Leave this page?',
    text: 'You have unsaved changes. Are you sure you want to go back? ',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it.',
    confirmButtonColor: '#DD6B55',
  })
  .then(({ value }) => {
    if (value){
      toggleRefreshState()
    }
  })
}

const createYoutubeChannel = (entityParams, createFn, navigate) => {
  createFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    navigate('/systemManager/youtube')
  })
}

const YoutubeAuthorization = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    accessToken, authCode, channelId, channelName, channels, emailAddress, noRefreshToken, refreshToken, videoServiceTypeId,
  } = state

  const navigate = useNavigate()

  const {
    callbacks: { createYoutubeChannel: createFn },
    creating,
  } = useYoutubeChannel()

  useEffect(() => {
    const params = {}
    const regex = /([^&=]+)=([^&]*)/g
    let m = regex.exec(fragmentString)

    while (m){
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2])
      m = regex.exec(fragmentString)
    }
    if (params?.state){ setState({ emailAddress: params.state }) }
    if (params?.code){ setState({ authCode: params.code }) }
  }, [])

  useEffect(() => {
    if (authCode){
      requestTokens(authCode, { toggleUpdateTokenState: (access, refresh) => setState({
        accessToken: access || '',
        refreshToken: refresh || '',
        noRefreshToken: !refresh.length,
      }) })
    }
  }, [authCode])

  useEffect(() => {
    if (accessToken !== ''){
      requestChannel(accessToken, { toggleUpdateChannelState: (id, list) => setState({
        channelId: id,
        channels: list,
      }) })
    }
  }, [accessToken])

 const refreshState = () => {
    setState({
      accessToken: '',
      authCode: null,
      channelId: '',
      channelName: '',
      channels: [],
      emailAddress: '',
      refreshToken: '',
      videoServiceTypeId: null,
    })

    navigate('/systemManager/youtube')
 }

  const checkRedirect = () => {
    if (authCode){
      confirmRedirect({ toggleRefreshState: () => updateTokens('revoke', accessToken, { toggleRefreshState: () => refreshState() }) })
    } else {
      refreshState()
    }
  }

  const save = () => {
    const youtubeParams = {
      name: channelName,
      refreshToken,
      accessToken,
      channelId,
      emailAddress,
      videoServiceTypeId,
    }

    createYoutubeChannel(youtubeParams, createFn, navigate)
  }

  return (
    <>
      <PageHeader
        activeTabBarItemKey="zones"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        title="Youtube Channel"
        offset={{ left: 70 }}
      />
      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {!authCode ? <YoutubeAuthentication /> : null}
            {authCode ? (
              <DashboardModule title="Tokens">
                <Box flexDirection="column" padding="large">
                  <Box flexDirection={['column', 'row']} marginBottom="large">
                    <FormField
                      boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
                      label="Access Token"
                      disabled
                    >
                      <textarea
                        disabled
                        name="accessToken"
                        style={{ color: '#8c8c8e', height: 82, resize: 'vertical' }}
                        type="text"
                        defaultValue={accessToken}
                      />
                    </FormField>
                  </Box>
                  <Box flexDirection={['column', 'row']} marginBottom="large">
                    <FormField
                      boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
                      label="Refresh Token"
                      errorMessage={noRefreshToken ? 'No refresh token generated. Please revoke token and try again.' : ''}
                    >
                      <textarea
                        disabled
                        name="refreshToken"
                        style={{ color: '#8c8c8e', height: 50, resize: 'vertical' }}
                        type="text"
                        defaultValue={refreshToken}
                      />
                    </FormField>
                  </Box>
                </Box>
              </DashboardModule>
            ) : null}
            {accessToken ? (
              <DashboardModule title="Channel Details">
                <Box flexDirection="column" padding="large">
                  <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
                    <FormField
                      boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
                      label="Name"
                    >
                      <input name="name" type="text" value={channelName} onChange={e => setState({ channelName: e.target.value })} />
                    </FormField>
                    <FormField
                      boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
                      label="Type"
                    >
                      <select
                        name="videoServiceTypeId"
                        onChange={e => setState({ videoServiceTypeId: e.target.value })}
                      >
                        <option value={0} disabled selected hidden>Please Select...</option>
                        {videoServiceTypes.map(x => (
                          <option value={x.id}>{x.name}</option>
                        ))}
                      </select>
                    </FormField>
                  </Box>
                  <Text color="bodyFontLightColor" fontSize="xsmall" paddingBottom="small">Channel ID</Text>
                  <Box border="1px solid" borderColor="lineColor" borderRadius={5}>
                    {channels.length > 0 ? channels.map(channel => (
                      <ListItem.WithCheckbox
                        boxProps={{
                        borderBottom: '1px solid',
                        paddingX: 'large',
                        paddingY: 'medium',
                      }}
                        checkbox={(
                          <Checkbox
                            checked={channelId === channel.id}
                            onClick={() => setState({ channelId: channel.id })}
                          />
                        )}
                        flexDirection="column"
                      >
                        <Text fontSize="xsmall" href={`https://www.youtube.com/channel/${channel.id}`} as="a" target="_blank">
                          {channel.id}
                        </Text>
                      </ListItem.WithCheckbox>
                    )) : (
                      <ListItem
                        boxProps={{
                          borderBottom: '1px solid',
                          padding: 'large',
                        }}
                        flexDirection="column"
                      >
                        <Text fontSize="xsmall" color="bodyFontLightColor">
                          No Available Channels
                        </Text>
                      </ListItem>
                    )}
                  </Box>
                </Box>
              </DashboardModule>
            ) : null}
          </Columns.Main>
          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Box padding="small" marginTop="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    size="medium"
                    onClick={() => checkRedirect()}
                  >
                    Back
                  </Button>
                </Box>
                <Box padding="small">
                  <Button
                    buttonStyle="primaryCreate"
                    size="medium"
                    loading={creating}
                    onClick={() => save()}
                    disabled={channelId === '' || channelName === '' || videoServiceTypeId === null || refreshToken === ''}
                  >
                    Save
                  </Button>
                </Box>
                <Box padding="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    size="medium"
                    onClick={() => updateTokens('revoke', accessToken, { toggleRefreshState: () => refreshState() })}
                    disabled={accessToken === ''}
                  >
                    Revoke Token
                  </Button>
                </Box>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

YoutubeAuthorization.propTypes = {}

export default YoutubeAuthorization
