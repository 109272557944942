import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Columns,
  DashboardModule,
  ModuleBlankState,
  PageHeader,
} from '@campaignhub/suit-theme'

import TasksImageUrl from '@components/assets/tasks.svg'

const Overview = () => (
  <>
    <PageHeader
      boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
      title="Default Dashboard"
      offset={{ left: 70 }}
    />
    <Box paddingX="large" paddingTop={[112, 105]}>
      <Columns
        boxProps={{ marginTop: 'large' }}
        flexDirection={['column', 'column', 'row']}
      >
        <Columns.Main>
          <DashboardModule title="No role assigned">
            <ModuleBlankState imageUrl={TasksImageUrl}>
              <ModuleBlankState.Paragraph>
                Please contact your administrator to assign your role.
              </ModuleBlankState.Paragraph>
            </ModuleBlankState>
          </DashboardModule>
        </Columns.Main>

        <Columns.Sidebar>
          <DashboardModule title="Actions">
            <Box flexDirection="column" padding="large">
              <Link to="/logout" reloadDocument style={{ textDecoration: 'none' }}>
                <Button buttonStyle="secondary" size="medium">
                  Log Out
                </Button>
              </Link>
            </Box>
          </DashboardModule>
        </Columns.Sidebar>
      </Columns>
    </Box>
  </>
)

export default Overview
