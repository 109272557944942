import useReduxAction from '@hooks/useReduxAction'
import useActivityLogTypes from '@hooks/useActivityLogTypes'
import useServiceJobStatuses from '@hooks/useServiceJobStatuses'

const BackOffice = (props) => {
  const { children } = props

  useReduxAction('emailReplacements', 'loadEmailReplacements', {})
  useReduxAction('serviceCategories', 'loadServiceCategories', {})
  useReduxAction('wordReplacements', 'loadWordReplacements', {})
  useReduxAction('zones', 'loadZones', {})

  useReduxAction('employmentTypesV3', 'loadEmploymentTypesV3', {})
  useReduxAction('userFlagsV3', 'loadUserFlagsV3', {})
  useReduxAction('userRolesV3', 'loadUserRolesV3', {})
  useReduxAction('userRoles', 'loadUserRoles', {})
  useReduxAction('providerFlagsV3', 'loadProviderFlagsV3', {})
  useReduxAction('serviceTypesV3', 'loadServiceTypesV3', {})
  useReduxAction('statesV3', 'loadStatesV3', {})
  useReduxAction('zonesV3', 'loadZonesV3', {})
  useReduxAction('zoneFlagsV3', 'loadZoneFlagsV3', {})

  useActivityLogTypes({ performHttpRequests: true })
  useServiceJobStatuses({ performHttpRequests: true })

  return children
}

export default BackOffice
