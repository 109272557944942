import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { SidebarNoticeBox } from '@campaignhub/suit-theme'

const ResourcePrompts = (props) => {
  const { resourceTypes, serviceJob, uploadState } = props

  const { addedCount, recentlyFailed, recentlyUploaded } = uploadState

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources } = entities

  const totalAddedFiles = addedCount?.reduce((accumulator, current) => accumulator + current.count, 0)

  let bracketCountError = false
  const currentBracketDayCount = Object.values(resources)
    .filter(x => x.serviceJobId === serviceJob.id && x.resourceTypeId === resourceTypes.find(type => type.name === 'BracketDay')?.id).length
  const currentBracketDuskCount = Object.values(resources)
    .filter(x => x.serviceJobId === serviceJob.id && x.resourceTypeId === resourceTypes.find(type => type.name === 'BracketDusk')?.id).length

  if (recentlyUploaded.length > 0 && recentlyUploaded.find(x => ['BracketDay', 'BracketDusk'].includes(x.resourceType.name))){
    bracketCountError = (currentBracketDayCount + currentBracketDuskCount) !== serviceJob.totalAssets * 5
  }

  return (
    <>
      {(recentlyUploaded.length > 0 || recentlyFailed.length > 0) && (
        <>
          <SidebarNoticeBox boxProps={{ marginBottom: 'medium' }}>
            <SidebarNoticeBox.Title>Resources</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              {recentlyUploaded.length} out of {totalAddedFiles} images uploaded.
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          {bracketCountError && (
            <SidebarNoticeBox boxProps={{ marginBottom: 'large' }}>
              <SidebarNoticeBox.Title>
                {currentBracketDayCount + currentBracketDuskCount} / {serviceJob?.totalAssets * 5} Required Bracket Resources
              </SidebarNoticeBox.Title>
              <SidebarNoticeBox.Paragraph>
                {`It seems like the shooting formula was not followed, are you sure you have uploaded the right amount of exposures?\n
                  In the event you did not shoot to the formula this will effect editing and potential turnaround time.\n
                  Please make sure moving forward the shooting formula is followed.`}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}
        </>
      )}
    </>
  )
}

ResourcePrompts.propTypes = {
  resourceTypes: PropTypes.array,
  serviceJob: PropTypes.object,
  uploadState: PropTypes.object,
}

export default ResourcePrompts
