import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import userTypes from '@functions/userTypes'

import {
  Box, Grid, ListItem, StatusBadge, Text, DashboardModule,
} from '@campaignhub/suit-theme'
import { faCrown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useCurrentUser from '@hooks/useCurrentUser'

import Icons from '@components/Icons'
import blankState from '@components/assets/blankState.svg'

import { esoftEditorId } from '@functions/api'
import { getEntityByName } from '@functions/getEntityByName'
import { getServiceStatusColor } from '@functions/getServiceStatusColor'
import { getDeadline } from '@functions/getDeadline'

import custom from '@styles/custom.module.scss'

const ServiceJobList = (props) => {
  const { filterJob, selectedItems, serviceJobs, showUsers, showQC, showRejection, totalJobCount, setJobState, providerView } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, serviceJobStatuses, users } = entities

  const { currentUser: { timeZone, userRole } } = useCurrentUser()

  const updateSelectedItems = (checked, id) => {
    if (checked) setJobState({ selectedItems: [...selectedItems, id] })
    else setJobState({ selectedItems: selectedItems.filter(x => x !== id) })
  }

  if (!serviceJobs.length){
    return (
      <DashboardModule.BlankState imageUrl={blankState}>
        <DashboardModule.BlankState.Title>No Jobs Available</DashboardModule.BlankState.Title>
        <DashboardModule.BlankState.Paragraph>
          Try adjusting your filters.
        </DashboardModule.BlankState.Paragraph>
      </DashboardModule.BlankState>
    )
  }

  return (
    <>
      <Box flexDirection="column" maxHeight="82vh" overflowY="auto">
        {serviceJobs.map((serviceJob) => {
          const now = DateTime.fromISO(DateTime.now(), { zone: timeZone })
          const today = now.startOf('day')
          const daysOffset = now > today.set({ hour: 18, minute: 0 }) ? 1 : 0
          const offsetDate = today.plus({ days: daysOffset }).endOf('day')

          const deadline = DateTime.fromISO(showUsers ? serviceJob.clientDeadline : serviceJob.dateService, { zone: timeZone })
          const proofedAt = DateTime.fromISO(serviceJob.proofedAt, { zone: timeZone })
          const serviceDate = DateTime.fromISO(serviceJob.dateService, { zone: timeZone })
          const pastDeadline = now > deadline
          const proofPastDeadline = proofedAt.invalid ? true : proofedAt > DateTime.fromISO(serviceJob.clientDeadline, { zone: timeZone })

          const currentDate = DateTime.fromISO(DateTime.now(), { zone: timeZone })
          const deadlineHoursDiff = currentDate.diff(deadline, ['years', 'months', 'days', 'hours', 'minutes'])
          const proofedHoursDiff = proofedAt.diff(deadline, ['years', 'months', 'days', 'hours', 'minutes'])

          const providerUsername = serviceJob.details?.find(x => x.entityFieldType.name === 'ServiceUsername')?.value
          const rhContact = serviceJob.details?.find(x => x.entityFieldType.name === 'RealStudiosDetails')?.value
          const workbaseDetails = serviceJob.details?.find(x => x.entityFieldType.name === 'WorkbaseDetails')?.value

          const editors = serviceJob.users.filter(user => user.serviceJobUserRoleId === 1)
          const qc = serviceJob.users.filter(user => user.serviceJobUserRoleId === 2)
          const rejection = serviceJob.users.filter(user => user.serviceJobUserRoleId === 3)

          return (
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                gridGap: 'large',
                padding: 'large',
                alignItems: 'center',
              }}
              flexDirection="row"
              forceBorderBottom
              justifyContent="center"
              key={serviceJob.id}
              showAngleIcon
            >
              {providerView ? null : (
                <Box flexDirection="column" width="auto">
                  <input
                    checked={selectedItems?.includes(serviceJob.id)}
                    className={custom.checkbox}
                    disabled={!users[esoftEditorId]?.services?.includes(serviceJob?.service?.id) || editors?.find(x => x.user.id === esoftEditorId)}
                    onChange={e => updateSelectedItems(e.target.checked, serviceJob.id)}
                    type="checkbox"
                  />
                </Box>
              )}
              <Link
                key={serviceJob.id}
                to={`/service-jobs/${serviceJob.id}`}
                target="_blank"
                style={{ textDecoration: 'none', width: '100%', display: 'flex' }}
              >
                <Box flexDirection={['column', 'column', 'row']}>
                  <Box flexDirection="column" width={showUsers ? ['100%', '100%', '45%'] : ['100%', '80%']} justifyContent="center">
                    <Box flexDirection="row">
                      <Box
                        alignItems="center"
                        color="bodyFontLightColor"
                        display="grid"
                        justifyContent="center"
                        marginRight="large"
                        width="auto"
                      >
                        <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name || 'Photography'} size={23} />
                      </Box>
                      <Box flexDirection="column" justifyContent="center">
                        <Box flexDirection="row" alignItems="center">
                          <Text fontSize="small" marginRight="small">
                            {serviceJob.campaign?.name}
                          </Text>
                          {serviceJob.client?.classification === 'VIP' && <FontAwesomeIcon icon={faCrown} color="#FEDE00" size="sm" />}
                          {!providerView && (
                            <Box width="auto" alignItems="center" marginX="small">
                              <Icons name={workbaseDetails ? 'ContentHouse' : rhContact ? 'Realhub' : 'Campaigntrack'} size={18} />
                            </Box>
                          )}
                        </Box>
                        {!providerView || (providerView && serviceDate < offsetDate) ? (
                          <Text fontSize="xsmall" color="bodyFontLightColor" marginTop="medium">
                            {serviceJob.client?.name}
                          </Text>
                        ) : null}
                        {providerView && (
                          <Text fontSize="xsmall" color="bodyFontLightColor" marginTop="medium">
                            {serviceJob?.name}
                          </Text>
                        )}
                      </Box>
                    </Box>
                    {providerView ? null : (
                      <Box flexDirection="row" alignItems="center" marginTop="medium">
                        <StatusBadge boxProps={{ marginRight: 'small' }} color="#282B42" ghost text={serviceJob.service?.name} />
                        <StatusBadge
                          boxProps={{ marginRight: 'small' }}
                          color={getServiceStatusColor(serviceJob.status?.name)}
                          ghost
                          text={serviceJob.status?.description}
                        />
                      </Box>
                    )}
                    {userRole?.userTypeId === userTypes?.find(x => x.name === 'Studio')?.id && !serviceDate.invalid && (
                      <Box flexDirection="row" marginTop="medium">
                        <Text fontSize="xsmall" color="bodyFontLightColor">
                          Service Date: {serviceDate.invalid ? '' : serviceDate.toFormat('y-LL-dd hh:mm a')}
                        </Text>
                      </Box>
                    )}
                  </Box>
                  {showUsers && (
                    <Grid
                      gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
                      gridGap="small"
                      width={['100%', '100%', '35%']}
                      paddingTop={['medium', 'medium', 0]}
                    >
                      <Box flexDirection="column" justifyContent="center">
                        <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
                          Provider
                        </Text>
                        {!providerUsername || JSON.parse(providerUsername)?.value?.trim() === '' ? (
                          <StatusBadge fontSize="small" color="green" text="Available" />
                        ) : (
                          <Text fontSize="small">{JSON.parse(providerUsername).value || ''}</Text>
                        )}
                      </Box>
                      <Box flexDirection="column" justifyContent="center">
                        <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
                          Editor
                        </Text>
                        {!editors.length ? (
                          <StatusBadge fontSize="small" color="green" text="Available" />
                        ) : (
                          editors.map(serviceJobUser => (
                            <Text key={serviceJobUser.user.firstName} fontSize="small">
                              {serviceJobUser.user.firstName} {serviceJobUser.user.lastName}
                            </Text>
                          ))
                        )}
                      </Box>
                      {showQC && (
                        <Box flexDirection="column" justifyContent="center">
                          <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
                            QC
                          </Text>
                          {!qc.length ? (
                            <StatusBadge fontSize="small" color="green" text="Available" />
                          ) : (
                            qc.map(serviceJobUser => (
                              <Text key={serviceJobUser.user.firstName} fontSize="small">
                                {serviceJobUser.user.firstName} {serviceJobUser.user.lastName}
                              </Text>
                            ))
                          )}
                        </Box>
                      )}
                      {showRejection && (
                        <Box flexDirection="column" justifyContent="center">
                          <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
                            Rejection
                          </Text>
                          {!rejection.length ? (
                            <StatusBadge fontSize="small" color="green" text="Available" />
                          ) : (
                            rejection.map(serviceJobUser => (
                              <Text key={serviceJobUser.user.firstName} fontSize="small">
                                {serviceJobUser.user.firstName} {serviceJobUser.user.lastName}
                              </Text>
                            ))
                          )}
                        </Box>
                      )}
                    </Grid>
                  )}
                  <Box
                    flexDirection="column"
                    justifyContent="center"
                    width={['100%', '100%', '20%']}
                    textAlign={['left', 'left', 'right']}
                    paddingTop={['medium', 'medium', 0]}
                  >
                    <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'}>
                      {deadline.invalid ? '' : deadline.toFormat('y-LL-dd')}
                    </Text>
                    <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'} paddingTop="small">
                      {deadline.invalid ? '' : deadline.toFormat('hh:mm a ccc')}
                    </Text>
                    {providerView ? null : (
                      <Text>
                        {((serviceJob.service.onSite && serviceJob.status.id < getEntityByName(serviceJobStatuses, 'Booked')?.id)
                          || (!serviceJob.service.onSite && serviceJob.status.id < getEntityByName(serviceJobStatuses, 'Uploaded')?.id))
                        && pastDeadline
                        && proofedAt.invalid ? null : !proofedAt.invalid ? (
                          <StatusBadge
                            boxProps={{ marginTop: 'small' }}
                            fontSize="small"
                            ghost
                            color={proofPastDeadline ? 'red' : 'green'}
                            text={!proofPastDeadline ? 'Submitted' : getDeadline(proofedHoursDiff.toObject(), proofPastDeadline, true)}
                          />
                        ) : (
                          !deadline.invalid && (
                            <StatusBadge
                              boxProps={{ marginTop: 'small' }}
                              fontSize="small"
                              color={
                                deadlineHoursDiff.toObject().days <= -1 && !pastDeadline
                                  ? 'green'
                                  : deadlineHoursDiff.toObject().days >= 0 && !pastDeadline
                                  ? '#E3B418'
                                  : 'red'
                              }
                              text={getDeadline(deadlineHoursDiff.toObject(), pastDeadline)}
                            />
                          )
                        )}
                      </Text>
                    )}
                    {userRole?.userTypeId === userTypes?.find(x => x.name === 'OnSite')?.id && serviceJob.service?.onSite && (
                      <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'} paddingTop="small">
                        {` (${!serviceJob.serviceJobLength ? 0 : serviceJob.serviceJobLength} minutes)`}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Link>
            </ListItem>
          )
        })}
      </Box>
      {serviceJobs.length > 0 && (
        <DashboardModule.LoadMoreFooter
          callbacks={{ loadMore: () => setJobState({ filterLimit: serviceJobs?.length + 50, filterJob: filterJob + 1, selectedAllItems: false }) }}
          canLoadMore={serviceJobs?.length < totalJobCount}
          entityCount={serviceJobs?.length}
        />
      )}
    </>
  )
}

ServiceJobList.propTypes = {
  filterJob: PropTypes.number,
  providerView: PropTypes.bool,
  serviceJobs: PropTypes.array.isRequired,
  totalJobCount: PropTypes.number,
  showUsers: PropTypes.bool,
  showQC: PropTypes.bool,
  showRejection: PropTypes.bool,
  selectedItems: PropTypes.array,
  setJobState: PropTypes.func,
}

export default ServiceJobList
